import React, { useEffect, useState } from 'react';
import { RED } from '@constants/colors';
import { Box, Image, VStack, HStack, Container } from '@chakra-ui/react';
import CustomButton from '@components/Button';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselProps } from '@customTypes/homepage';
import { useNavigate } from 'react-router-dom';
import fetchRequest from '@utils/fetcher';



import {
  HOMEPAGE_TITLE,
  HOMEPAGE_DESC,
  PRODUCT_TITLE,
  TESTIMONIAL_TITLE,
  BANNER_TITLE,
  BANNER_DESC,
  SECTION_TITLE,
} from '@constants/texts';
import imgtest from '@assets/test img.png';

const Hero = () => {
  const [carouselData, setCarouselData] = useState<CarouselProps[]>([]);
  const navigate = useNavigate();

  const settings = {
    arrows: false,
    autoplay: true,
    centerMode: true,
    infinite: true,
    variableWidth: true,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: carouselData.length - 1,
  };

  const getCarouselData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'homepage' }).then(
        (response) => {
          setCarouselData(response.carousel);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCarouselData();
  }, []);

  return (
    <Slider {...settings} adaptiveHeight={true}>
      {carouselData.map((content, index) => (
        <Box
          key={index}
          paddingX={{ lg: '50px', sm: '30px', base: '10px' }}
          paddingTop={{ lg: '25px', md: '20px', base: '15px' }}
          paddingBottom={{ lg: '50px', md: '30px', base: '20px' }}
        >
          <HStack
            w={{
              xl: '1048px',
              lg: '848px',
              md: '648px',
              sm: '348px',
              base: '300px',
            }}
            h={{ lg: '450px', md: '300px', base: '200px' }}
            padding={{ md: '10px', base: '8px' }}
            shadow={'xl'}
            borderRadius={'lg'}
            bg={'white'}
          >
            <Image
              src={content.imageUrl}
              w={{
                xl: '422px',
                lg: '320px',
                md: '250px',
                sm: '160px',
                base: '120px',
              }}
              h={{ lg: '395px', md: '250px', sm: '160px', base: '120px' }}
              objectFit={'contain'}
            ></Image>
            <VStack w={{ base: '60%' }} align={'left'}>
              <Container
                fontSize={{ lg: '18px', md: '12px', base: '6px' }}
                className={'fontSemiBold'}
                color={RED}
              >
                {content.section}
              </Container>
              <Container
                fontSize={{
                  xl: '25px',
                  lg: '20px',
                  md: '16px',
                  base: '8px',
                }}
                className={'fontSemiBold'}
              >
                {content.title}
              </Container>
              <Container
              noOfLines={4}
                fontSize={{
                  lg: '16px',
                  md: '12px',
                  base: '8px',
                }}
                className={'fontRegular'}
                textAlign={{ md: 'justify', base: 'left' }}
              >
                {content.content}
              </Container>
              <Box paddingLeft={3} >
                <CustomButton
                  text="SELENGKAPNYA"
                  variant="red"
                  icon="none"
                  w="220px"
                  wMobile="100px"
                  onClick={() => {
                    const type = content.linkTo.split('/')[0];
                    navigate(
                      '/service/' +
                        type +
                        '/' +
                        encodeURIComponent(content.linkTo)
                    );
                  }}
                />
              </Box>
            </VStack>
          </HStack>
        </Box>
      ))}
    </Slider>
  );
};

export default Hero;
