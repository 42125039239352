import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, VStack, Image, Center } from '@chakra-ui/react';
import {
  LOGO_COLOR_MEANING,
  LOGO_COLOR_TITLE,
  LOGO_PHILOSOPHY_TITLE,
  LOGO_SYMBOL_MEANING,
  LOGO_SYMBOL_TITLE,
  MISI_TITLE,
  VISI_MISI_TITLE,
  VISI_TITLE,
} from '@constants/texts';
import { BLACK, GOLD, LIGHTGREY, RED, WHITE } from '@constants/colors';
import { BREADCRUMB_VISI_MISI } from '@constants/breakcrumbs';
import { QuickLinkProps } from '@customTypes/quickLink';
import fetchRequest from '@utils/fetcher';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import logo_picture from '@assets/logo.svg';
import logo_flower from '@assets/aboutUs/visiMisi/flower-logo.svg';
import { MissionProps, PhilosophyProps } from '@customTypes/visionMission';

const VisiMisi = () => {
  const [vision, setVision] = useState<string>('');
  const [mission, setMission] = useState<MissionProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [colorPhilosophyData, setColorPhilosophyData] =
    useState<PhilosophyProps>({
      description: '',
      image: '',
      detail: [],
    });
  const [symbolPhilosophyData, setSymbolPhilosophyData] =
    useState<PhilosophyProps>({
      description: '',
      image: '',
      detail: [],
    });

    const getResearchData = async () => {
      try {
        await fetchRequest({
          method: 'GET',
          path: 'about-us/vision-mission',
        }).then((response) => {
          setVision(response.vision);
          setMission(response.mission);
          setQuickLinkList(response.quickLinks || []);
          setColorPhilosophyData(
            response.colorPhilosophy || { description: '', image: '', detail: [] }
          );
          setSymbolPhilosophyData(
            response.symbolPhilosophy || {
              description: '',
              image: '',
              detail: [],
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    };

  useEffect(() => {
    getResearchData();
  }, []);

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_VISI_MISI} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '65%', md: '75%', sm: '90%' }}>
          <Text
            className={'fontSemiBold'}
            fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
            color={BLACK}
            align={'center'}
          >
            {VISI_MISI_TITLE}
          </Text>
          <br />
          <Box
            h={{ lg: '143px' }}
            padding={'24px'}
            bg={RED}
            color={WHITE}
            borderRadius={'lg'}
          >
            <Text
              className="fontSemiBold"
              fontSize={{ lg: '24px', base: '20px' }}
            >
              {VISI_TITLE}
            </Text>
            <Text fontSize={{ lg: '20px', base: '14px' }} textAlign={'justify'}>
              {vision}
            </Text>
          </Box>
          <br />
          <Box>
            <Text className="fontSemiBold" fontSize={'24px'} color={RED}>
              {MISI_TITLE}
            </Text>
            <Stack
              gap={'20px'}
              h={{ lg: '460px', base: '100%' }}
              align={'center'}
              justify={'center'}
              direction={{ lg: 'row', base: 'column' }}
            >
              {mission.map((m, i) => (
                <Box w={'260px'} key={i}>
                  <Box h={'240px'}>
                    <Image src={m.icon} boxSize={'100%'} objectFit={'fill'} />
                  </Box>
                  <br />
                  <VStack
                    h={{ lg: '180px', base: '80px' }}
                    padding={'10px'}
                    align={'left'}
                  >
                    <Text
                      fontSize={{ lg: '18px', base: '14px' }}
                      align={'center'}
                    >
                      {m.content}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </Stack>
          </Box>
          <br />
          <Box w={'100%'}>
            <Text className="fontSemiBold" fontSize={'24px'} color={RED}>
              {LOGO_PHILOSOPHY_TITLE}
            </Text>
            <Center paddingY={'30px'}>
              <Image
                src={colorPhilosophyData.image}
                w={{ lg: '381px', base: '240px' }}
                h={{ lg: '90px', base: '57px' }}
                objectFit={'fill'}
              />
            </Center>
            <br />
            <Center> {colorPhilosophyData.description} </Center>
            <br />
            {colorPhilosophyData.detail.map((dt, i) => (
              <>
                <Box
                  key={dt.color + '_' + i}
                  w={'100%'}
                  padding={'24px'}
                  borderRadius={'lg'}
                  fontSize={{ lg: '18px', base: '14px' }}
                  bg={dt.text}
                  color={WHITE}
                  textAlign={'justify'}
                >
                  {dt.color}
                </Box>
                <br />
              </>
            ))}
            <Center paddingY={'30px'}>
              <Image
                src={symbolPhilosophyData.image}
                w={{ lg: '400px', base: '200px' }}
                h={{ lg: '250px', base: '130px' }}
                objectFit={'fill'}
              />
            </Center>
            <br />
            <Center> {symbolPhilosophyData.description} </Center>
            <br />
            {symbolPhilosophyData.detail && symbolPhilosophyData.detail.length > 0 ? (
  symbolPhilosophyData.detail.map((m, i) => (
    <Box key={i}>
      <Box
        w={'100%'}
        padding={'24px'}
        borderRadius={'lg'}
        fontSize={{ lg: '18px', base: '14px' }}
        bg={m.text}
        textAlign={'justify'}
      >
        {m.color}
      </Box>
      <br />
    </Box>
  ))
) : (
  <Text>No symbol details available</Text>
)}
          </Box>
          <br />
        </Box>
      </Stack>
    </Box>
  );
};

export default VisiMisi;